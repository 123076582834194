
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { OneTimeCostValidator } from "../../../utils/FinancialPlanningValidatorUtils";
import DescriptionModal from "./modal/Description.vue";

export default defineComponent({
  name: "incme-household",
  props: ["theRisk"],
  components: {
    Form,
    Field,
    ErrorMessage,
    ElCurrencyInput,
    ElCurrencyInputNoSymbol,
    DescriptionModal,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const oneTomeCostForm = ref(null);
    const submitAboutYouForm = () => oneTomeCostForm.value?.submit();

    return {
      nFormat,
      submitAboutYouForm,
    };
  },
  data() {
    const actions = "addSpouse";

    const dialogVisible_1 = false;
    const dialogVisible_2 = false;

    const longTermsLiabilityOptions = [
      {
        label: "Investment (ASB, Shares, Land)",
        value: "Investment (ASB, Shares, Land)",
      },
      {
        label: "Car Loan",
        value: "Car Loan",
      },
    ];
    const shotTermsLiabilityOptions = [
      {
        label: "Credit Card",
        value: "Credit Card",
      },
      {
        label: "Personal Loan",
        value: "Personal Loan",
      },
      {
        label: "Line of Credit",
        value: "Line of Credit",
      },
      {
        label: "Overdraft",
        value: "Overdraft",
      },
    ];

    const description = {
      emergencyFund: `<p>An emergency fund is a financial safety net that helps you manage unexpected expenses and financial risks. It serves as a buffer against unforeseen events, reduces the need for debt, and provides peace of mind. Having an emergency fund is a crucial part of risk planning and ensures your financial stability during challenging times.</p>`,
      funeral: `<p>Calculate what sums will incur for funeral expenses, uncovered medical payments, final estate settlements costs and legal costs.</p>`,
      properties: `<p>Property is a valuable asset that can be used to generate income or as a form of security for a loan. It is important to know the value of your property and the amount of mortgage outstanding. This will help you to determine if you have a surplus or shortfall in your property.</p><ul><li>If your first property has shortfall (market value is less than mortgage balance), it is advisable to keep the property.</li><li>For other property, you may decide to sell or keep for other properties. If sell and incur shortfall, insurance planning will provide for the shortfall as debt. If sell and incur surplus, the surplus will be considered as asset.</li></ul>`,
      longTermLiabilities: `<p>These are financial obligations characterized by a repayment period extending beyond twelve (12) months. This category typically encompasses debts such as mortgage loans and hire purchase agreements. What distinguishes these liabilities is that they are secured by collateral, meaning specific assets are pledged as a guarantee for repayment. This arrangement mitigates the lender's risk, as they can claim the collateral if the borrower defaults. Including such liabilities in risk planning is essential, as they represent long-term financial commitments that could significantly influence an entity's fiscal health and credit standing.</p>`,
      ShortTermLiabilities: `<p>These are financial obligations that must be repaid within twelve (12) months. This category typically encompasses debts such as credit card balances and personal loans. What distinguishes these liabilities is that they are unsecured, meaning no collateral is pledged as a guarantee for repayment. This arrangement increases the lender's risk, as they cannot claim any assets if the borrower defaults. Including such liabilities in risk planning is essential, as they represent short-term financial commitments that could significantly influence an entity's fiscal health and credit standing.</p>`,
    };

    return {
      risk: this.theRisk,
      actions,
      dialogVisible_1,
      dialogVisible_2,
      // oneTimeCostValidator: OneTimeCostValidator,
      longTermsLiabilityOptions,
      shotTermsLiabilityOptions,
      description,
    };
  },
  methods: {
    onRemoveProperty(index) {
      this.risk.properties.splice(index, 1);
    },
    onAddProperty() {
      this.risk.properties.push({
        current_value: null,
        current_balance: null,
        is_to_sell: false,
        surplus_shortfall: null,
      });
    },
    onNextStep() {
      this.$emit("next", this.risk);
    },
    onPrevStep() {
      this.$emit("prev", this.risk);
    },
    calculateSurplusShortfall(index) {
      const property = this.risk.properties[index];
      const current_value = property.current_value;
      const current_balance = property.current_balance;
      const is_to_sell = property.is_to_sell;

      if (is_to_sell) {
        property.surplus_shortfall = current_value - current_balance;
      } else {
        property.surplus_shortfall = -current_balance;
      }
    },
    onRemoveLiability(index) {
      this.risk.long_term_liabilities.splice(index, 1);
    },
    onAddLongTermLiability() {
      this.risk.long_term_liabilities.push({
        liability_type: null,
        current_value: null,
        current_balance: null,
        is_to_sell: false,
        surplus_shortfall: null,
      });
    },
    calculateSurplusShortfallLiabilities(index) {
      const liability = this.risk.long_term_liabilities[index];
      const current_value = liability.current_value;
      const current_balance = liability.current_balance;
      const is_to_sell = liability.is_to_sell;

      if (is_to_sell) {
        liability.surplus_shortfall = current_value - current_balance;
      } else {
        liability.surplus_shortfall = -current_balance;
      }
    },
    onRemoveShortLiability(index) {
      this.risk.short_term_liabilities.splice(index, 1);
    },
    onAddShortTermLiability() {
      this.risk.short_term_liabilities.push({
        liability_type: null,
        amount_owing: null,
      });
    },
  },
  watch: {},
});

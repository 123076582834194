
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";

import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  profileDetailsValidatorForRiskPlanning,
  spouseValidatorForRiskPlanning,
} from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  name: "incme-household",
  props: ["theRisk"],
  components: {
    Form,
    Field,
    ErrorMessage,
    ElCurrencyInput,
    ElCurrencyInputNoSymbol,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const aboutYouForm = ref(null);
    const submitAboutYouForm = () => aboutYouForm.value?.submit();

    return {
      nFormat,
      submitAboutYouForm,
    };
  },
  data() {
    const actions = "addSpouse";
    const spouse = {
      name: "",
      age: null,
      annual_income: null,
      retirement_age: null,
      percentage_of_annual_income: null,
      years_to_retirement: null,
      annual_retirement_income: null,
      retirement_fund_required: null,
    };

    const child = {
      name: "",
      age: null,
      annual_income: null,
      retirement_age: null,
      percentage_of_annual_income: null,
      years_to_retirement: null,
      annual_retirement_income: null,
      retirement_fund_required: null,
    };

    const dependant = {
      name: "",
      age: null,
      annual_income: null,
      retirement_age: null,
      percentage_of_annual_income: null,
      years_to_retirement: null,
      annual_retirement_income: null,
      retirement_fund_required: null,
    };

    return {
      risk: this.theRisk,
      actions,
      spouse,
      child,
      dependant,
      // aboutYouValidator: profileDetailsValidatorForRiskPlanning,
    };
  },
  methods: {
    onRemoveSpouse(index) {
      this.risk.spouses.splice(index, 1);
    },
    onAddSpouse() {
      this.risk.spouses.push(this.spouse);
    },
    onRemoveChild(index) {
      this.risk.children.splice(index, 1);
    },
    onAddChild() {
      this.risk.children.push(this.child);
    },
    onRemoveDependant(index) {
      this.risk.dependants.splice(index, 1);
    },
    onAddDependant() {
      this.risk.dependants.push(this.dependant);
    },
    onNextStep() {
      if (this.risk.about_you.is_has_spouse && this.risk.spouses.length == 0) {
        Swal.fire({
          title: "Error!",
          text: "Please add at least one spouse.",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }

      this.$emit("next", this.risk);
    },
  },
  watch: {
    "risk.about_you.is_has_spouse": function (newValue, oldValue) {
      // console.log(newValue);
    },
  },
});

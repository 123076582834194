
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import DescriptionModal from "./modal/Description.vue";

import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  profileDetailsValidatorForRiskPlanning,
  spouseValidatorForRiskPlanning,
} from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  name: "incme-household",
  props: ["theRisk"],
  components: {
    // DescriptionModal,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const aboutYouForm = ref(null);
    const submitAboutYouForm = () => aboutYouForm.value?.submit();

    return {
      nFormat,
      submitAboutYouForm,
    };
  },
  data() {
    const replacement_income = {
      total_spouse_income: 0,
      total_children_income: 0,
      total_other_income: 0,
      total: 0,
    };

    const education_cost = 0;
    const housing_loan = 0;
    const property_needs = 0;
    const property_assets = 0;
    const liability_needs = 0;
    const liability_assets = 0;
    const short_term_liabilities = 0;
    const total_coverage_needed = 0;
    const total_resourse_available = 0
    return {
      risk: this.theRisk,
      replacement_income,
      education_cost,
      housing_loan,
      property_needs,
      property_assets,
      liability_needs,
      liability_assets,
      short_term_liabilities,
      total_coverage_needed,
      total_resourse_available
    };
  },
  methods: {
    onSave() {
      this.$emit("save", this.risk);
    },
    onPrevStep() {
      this.$emit("prev", this.risk);
    },
  },
  async mounted() {
    this.risk.spouses.forEach((spouse) => {
      this.replacement_income.total_spouse_income +=
        spouse.total_income_replacement;
    });
    this.risk.children.forEach((child) => {
      this.replacement_income.total_children_income +=
        child.total_income_replacement;
    });
    this.risk.dependants.forEach((dependant) => {
      this.replacement_income.total_other_income +=
        dependant.total_income_replacement;
    });

    this.replacement_income.total =
      this.replacement_income.total_spouse_income +
      this.replacement_income.total_children_income +
      this.replacement_income.total_other_income;

    this.risk.children.forEach((child) => {
      this.education_cost += child.present_value_for_education_needs;
    });

    this.risk.properties.forEach((property) => {
      if (!property.is_to_sell) {
        this.property_needs += Math.abs(property.surplus_shortfall);
      } else {
        this.property_assets += Math.abs(property.surplus_shortfall);
      }
    });

    this.risk.long_term_liabilities.forEach((lia) => {
      if (!lia.is_to_sell) {
        this.liability_needs += Math.abs(lia.surplus_shortfall);
      } else {
        this.liability_assets += Math.abs(lia.surplus_shortfall);
      }
    });

    this.risk.short_term_liabilities.forEach((lia) => {
      this.short_term_liabilities += lia.amount_owing;
    });

    this.total_coverage_needed =
      this.risk.retirementPincome_for_spouse
        .total_retirement_fund_required_for_spouse +
      this.replacement_income.total +
      this.education_cost +
      this.property_needs +
      this.liability_needs +
      Math.abs(this.short_term_liabilities) +
      this.risk.one_time_cost.emergency_fund +
      this.risk.one_time_cost.funeral;

    this.total_resourse_available =
      this.property_assets +
      this.liability_assets +
      this.risk.assets.life_insurance +
      this.risk.assets.savings +
      this.risk.assets.epf_balance +
      this.risk.assets.investment +
      this.risk.assets.other_assets +
      this.risk.assets.epf_funeral_benefit +
      this.risk.assets.socso_funeral_benefit;
  },
});

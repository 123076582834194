
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter, useRoute } from "vue-router";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import {
  defaultProfile,
  defaultParent,
  defaultExecutors,
  defaultTrustees,
} from "../../../utils/ProfileUtils";

import AboutYou from "./AboutYou.vue";
import OneTimeCost from "./OneTimeCost.vue";
import RetirementIncome from "./RetirementIncome.vue";
import EducationCostForChildren from "./EducationCostForChildren.vue";
import ReplacementIncome from "./ReplacementIncome.vue";
import AvailableAssets from "./AvailableAssets.vue";
import Summary from "./Summary.vue";

export default defineComponent({
  name: "calculators",
  components: {
    Field,
    AboutYou,
    OneTimeCost,
    ReplacementIncome,
    RetirementIncome,
    EducationCostForChildren,
    AvailableAssets,
    Summary,
  },
  setup() {
    const { n } = useI18n();

    return {
      n,
    };
  },
  data() {
    const customerNRICToSearch = "";
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    // const riskPlannings = [] as any;
    const riskPlanning = {
      id: null,
      user_id: null,
      type: null,
      raw: null,
    };

    const risk = {
      about_you: {
        age: 40,
        annual_income: 100000,
        is_has_spouse: true,
        is_has_cildren: true,
        is_has_dependant: true,
      },
      one_time_cost: {
        funeral: 2500,
        outstanding_mortgage: 300000,
        debt: 10000,
        emergency_fund: 2000,
      },
      replacement_income: {
        desired_annual_income: 5000,
        current_inflation_rate: 3,
        current_rate_of_return: 6,
        total_spouses: null,
        total_children: null,
        total_dependants: null,
      },
      retirement_income: {
        desired_annual_income: 5000,
        years_income_be_needed: 20,
        total_retirement_fund_required_for_spouse: null,
      },
      educational_cost: {
        education_fees_inflation_rate: 3,
      },
      spouses: [
        {
          name: "Wife 1",
          age: 53,
          annual_income: 100000,
          retirement_age: 60,
          percentage_of_annual_income: 50,
          amount_of_annual_income: null,
          years_to_receive_income: 20,
          years_to_retirement: null,
          total_income_replacement: null,
          annual_salary_increment_rate: 0,
          annual_retirement_income: null,
          percentage_desired_retirement_income: 70,
          expected_lifespan: 80,
          retirement_fund_required: null,
        },
      ],
      children: [
        {
          name: "Child 1",
          age: 10,
          annual_income: 0,
          retirement_age: 55,
          percentage_of_annual_income: null,
          amount_of_annual_income: null,
          years_to_receive_income: null,
          years_to_retirement: null,
          years_to_college: 10,
          years_in_college: 4,
          present_annual_edu_cost: 49000,
          future_value_edu_cost: null,
          present_value_annuity_due: null,
          total_income_replacement: null,
          annual_retirement_income: null,
          retirement_fund_required: null,
          present_value_for_education_needs: null,
        },
      ],
      dependants: [
        {
          name: "Mother",
          age: 10,
          annual_income: 60,
          retirement_age: 55,
          percentage_of_annual_income: null,
          amount_of_annual_income: null,
          years_to_receive_income: null,
          years_to_retirement: null,
          total_income_replacement: null,
          annual_retirement_income: null,
          retirement_fund_required: null,
        },
      ],
      properties: [
        {
          current_balance: 100000,
          current_value: 200000,
          is_to_sell: false,
          surplus_shortfall: -100000,
        },
        {
          current_balance: 200000,
          current_value: 100000,
          is_to_sell: false,
          surplus_shortfall: -200000,
        },
      ],
      long_term_liabilities: [
        {
          liability_type: "Investment (ASB, Shares, Land)",
          current_balance: 100000,
          current_value: 200000,
          is_to_sell: false,
          surplus_shortfall: -100000,
        },
      ],
      short_term_liabilities: [
        {
          liability_type: "Credit Card",
          amount_owing: 10000,
        },
      ],
      retirementPincome_for_spouse: {
        desired_annual_income: 5000,
        years_income_be_needed: 20,
        total_retirement_fund_required_for_spouse: null,
      },
      assets: {
        savings: 100000,
        investment: 200000,
        life_insurance: 100000,
        other_assets: 100000,
        epf_balance: 100000,
        epf_funeral_benefit: 5000,
        socso_funeral_benefit: 1000,
      },
    };

    const risk_description = {};

    return {
      store: useStore(),
      cstmr_id,
      // riskPlannings,
      riskPlanning,
      isDataReady: false,
      customerNRICToSearch,
      active: 0,
      risk,
      risk_description,
    };
  },
  methods: {
    init() {
      if (!this.cstmr_id) {
        this.showSearchCustomerModal();
      } else {
        this.getRiskPlanning(this.cstmr_id);
      }
    },
    handleChange(val) {
      // console.log(val);
    },
    showSearchCustomerModal() {
      new Modal("#searchCustomerModal", {
        backdrop: "static",
        keyboard: false,
      }).show();
    },
    searchCustomerByNRIC() {
      this.showSwalLoading("Fetching Customer Data");
      this.store
        .dispatch(Actions.GET_CUSTOMER_BY_NRIC, this.customerNRICToSearch)
        .then(() => {
          let theProfile = this.store.getters.getCustomerData;
          if (theProfile.id) {
            this.cstmr_id = theProfile.id;
            setTimeout(() => {
              this.redirecting();
            }, 1000);
          } else {
            this.showSwalResult("Customer not found", "error");
          }
        });
    },
    redirecting() {
      this.showSwalLoading("Redirecting");
      setTimeout(() => {
        this.$router
          .push({
            path:
              "/customers/details/" +
              this.cstmr_id +
              "/financial-planning/risk-planning",
          })
          .then(() => {
            window.location.reload();
          });
      }, 1000);
    },
    getRiskPlanning(user_id) {
      // this.getUserProfile(user_id);
      this.showSwalLoading("Populating Data");
      this.store.dispatch(Actions.GET_RISK_PLANNING, user_id).then(() => {
        let risk = this.store.getters.getRiskPlanningData;
        if (risk) {
          this.riskPlanning = risk;
          this.risk = this.riskPlanning.raw;
        }
        console.log("risk", this.riskPlanning);
        this.getUserProfile(user_id);
      });
    },
    getUserProfile(user_id) {
      this.store.dispatch(Actions.GET_USER_BASIC_PROFILE, user_id).then(() => {
        // this.profile = this.store.getters.getCustomerBasicProfileData;
        // this.isMuslim = this.profile.user_raw_profile?.raw?.is_muslim ?? false;
        setTimeout(() => {
          this.isDataReady = true;
          Swal.close();
        }, 1000);
      });
    },
    // getFamilyProfile() {
    //   this.store
    //     .dispatch(Actions.GET_USER_FAMILY_PROFILE, this.profile.id)
    //     .then(() => {
    //       const family = this.store.getters.getFamilyProfileData
    //         .user_raw_family;
    //       if (family !== null) {
    //         this.spouses = family.raw.spouses;
    //         this.children = family.raw.children;
    //         this.parents = family.raw.parents;
    //         this.siblings = family.raw.siblings;
    //         this.others = family.raw.others;
    //       }
    //       setTimeout(() => {
    //         // this.getWillProfile();
    //         Swal.close();
    //       }, 500);
    //     });
    // },
    showSwalLoading(message) {
      Swal.fire({
        title: message,
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    },
    showSwalResult(message, icon = "success") {
      Swal.fire({
        icon: icon,
        title: message,
        text: "Do you want to proceed?",
        allowOutsideClick: false,
      }).then((result) => {
        if (message == "Customer not found") {
          this.showSearchCustomerModal();
        }
      });
    },
    // saveRiskPlanning(risk_planning) {
    //   this.showSwalLoading("Saving Risk Planning");

    //   setTimeout(() => {
    //     const data = {
    //       user_id: this.cstmr_id,
    //       type: "risk",
    //       raw: risk_planning,
    //     };
    //     this.store.dispatch(Actions.STORE_RISK_PLANNING, data).then(() => {
    //       this.showSwalResult("Risk Planning has been saved");
    //     });
    //     Swal.close();
    //   }, 500);
    // },
    next() {
      this.onSaveRiskPlanning("next");
    },
    prev() {
      this.onSaveRiskPlanning("prev");
      this.active--;
    },
    onSaveRiskPlanning(action = "next") {
      Swal.fire({
        title: "Saving Your Risk Planning Information",
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      setTimeout(() => {
        let user_id = null;
        if( typeof this.cstmr_id == 'string' ){
          user_id = parseInt(this.cstmr_id);
        }

        const data = {
          id: this.riskPlanning.id,
          user_id: user_id,
          type: "risk",
          raw: this.risk,
        };

        console.log("data", data);
        this.store.dispatch(Actions.STORE_RISK_PLANNING, data).then(() => {
          let risk = this.store.getters.getRiskPlanningData;

          Swal.close();
        });
        if (action == "next" && this.active < 6) this.active++;
        else if (action == "prev" && this.active > 0) this.active--;
      }, 300);
    },
    // saveProfile(type, showResult = true) {
    //   console.log("saveProfile", type);
    //   this.store.dispatch(Actions.UPDATE_PROFILE, this.profile).then(() => {
    //     if (type == "Spouses" && showResult) {
    //       this.saveRaw(type, "family");
    //     } else {
    //       if (showResult) {
    //         Swal.fire({
    //           icon: "success",
    //           title: "Personal Information has been saved",
    //           allowOutsideClick: false,
    //         });
    //       }
    //       this.showRiskPlanningSummaryModal = true;
    //     }
    //   });
    // },
    // saveRaw(saved, type, showResult = true) {
    //   console.log("saveProfile", type);

    //   let raw = {};

    //   if (type == "family") {
    //     raw = {
    //       spouses: this.spouses,
    //       children: this.children,
    //       parents: this.parents,
    //       siblings: this.siblings,
    //       others: this.others,
    //     };
    //   }
    //   if (type == "will") {
    //     raw = {
    //       executors: this.executors,
    //       trustees: this.trustees,
    //       distribution: this.distribution,
    //       special_request: this.special_request,
    //       personal_belongings: this.personal_belongings,
    //       residuary_estate: this.residuary_estate,
    //     };
    //   }

    //   let data = {
    //     type: type,
    //     user_id: this.profile.id,
    //     raw: raw,
    //   };

    //   this.store.dispatch(Actions.STORE_RAW, data).then(() => {
    //     if (showResult) {
    //       this.showSwalResult("Your " + saved + " Information has been saved");
    //       this.showRiskPlanningSummaryModal = true;
    //     }
    //   });
    // },
    // submitRiskPlanning(action) {
    //   if (action == "preview") {
    //     this.showSwalLoading("Summarizing Risk Planning");

    //     setTimeout(() => {
    //       Swal.close();
    //       new Modal("#RiskPlanningSummaryModal", {
    //         backdrop: "static",
    //         keyboard: false,
    //       }).show();
    //     }, 1500);
    //   } else {
    //     Swal.fire({
    //       title: "Are you sure?",
    //       text: "You won't be able to revert this!",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonText: "Yes, submit it!",
    //       cancelButtonText: "No, cancel!",
    //       customClass: {
    //         confirmButton: "btn btn-primary btn-active-primary",
    //         cancelButton: "btn btn-danger btn-active-primary ms-2",
    //       },
    //     }).then((result) => {
    //       this.showSwalLoading("Submitting Risk Planning");

    //       setTimeout(() => {
    //         Swal.close();

    //         const data = {
    //           user_id: this.profile.id,
    //           type: "will",
    //           raw: {
    //             profile: this.profile,
    //             spouses: this.spouses,
    //             children: this.children,
    //             parents: this.parents,
    //             siblings: this.siblings,
    //             others: this.others,
    //             executors: this.executors,
    //             trustees: this.trustees,
    //             distribution: this.distribution,
    //             special_request: this.special_request,
    //             personal_belongings: this.personal_belongings,
    //             residuary_estate: this.residuary_estate,
    //           },
    //         };

    //         this.store.dispatch(Actions.STORE_WILL_PLANNING, data).then(() => {
    //           this.showSwalResult("Risk Planning has been submitted");
    //         });
    //       }, 1500);
    //     });
    //   }
    // },
    getRouteUrl(route) {
      return this.$router.resolve(route).href;
    },
  },
  async mounted() {
    this.init();
  },
});


import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { OneTimeCostValidator } from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  name: "incme-household",
  props: ["theRisk"],
  components: {
    Form,
    Field,
    // ErrorMessage,
    // ElCurrencyInput,
    // ElCurrencyInputNoSymbol,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const addSpouseModal = ref(null);
    const oneTomeCostForm = ref(null);
    const closeModal = () => Modal.getInstance(addSpouseModal.value)?.hide();
    const submitAboutYouForm = () => oneTomeCostForm.value?.submit();

    return {
      nFormat,
      addSpouseModal,
      closeModal,
      submitAboutYouForm,
    };
  },
  data() {
    return {
      risk: this.theRisk,
      // retirementIncomeValidator: OneTimeCostValidator,
    };
  },
  methods: {
    calculateAnnualRetirementIncome(index) {
      const pmt = this.calculateFV(index);
      return pmt;
    },
    calculateFV(index) {
      const pv = this.risk.spouses[index].annual_income;
      const n = this.yearsToRetirement(index);
      const i = this.risk.spouses[index].annual_salary_increment_rate / 100;

      return pv * Math.pow(1 + i, n);
    },
    calculateFirstYearRetirementIncome(index) {
      const annualRetirementIncome = this.calculateAnnualRetirementIncome(
        index
      );
      const percentage =
        this.risk.spouses[index].percentage_desired_retirement_income / 100;
      const pmt = annualRetirementIncome * percentage;

      return pmt;
    },
    calculateRetirementFundRequired(index) {
      const pmt =
        this.calculateFV(index) *
        (this.risk.spouses[index].percentage_desired_retirement_income / 100);
      const n =
        this.risk.spouses[index].expected_lifespan -
        this.risk.spouses[index].retirement_age;
      const i = this.calculateRealRateOfReturn();

      //const PV = pmt * ((Math.pow(1 + i, n) - 1) / i) * (1 + i);
      //const PV = pmt * ((Math.pow(1 + i, n) - 1) / i);
      // const PV = pmt * ((1 - Math.pow(1 + i, -n)) / i);
      const PV = pmt * ((1 - Math.pow(1 + i, -n)) / i) * (1 + i);

      return PV;
    },
    yearsToRetirement(index) {
      return (
        this.risk.spouses[index].retirement_age - this.risk.spouses[index].age
      );
    },
    calculateRealRateOfReturn() {
      let inflationRate =
        this.risk.replacement_income.current_inflation_rate / 100;
      let rateOfReturn =
        this.risk.replacement_income.current_rate_of_return / 100;

      return (1 + rateOfReturn) / (1 + inflationRate) - 1;
    },
    calculatePresentRetirementFundRequired(index) {
      const FV = this.calculateRetirementFundRequired(index);
      const n =
        this.risk.spouses[index].retirement_age -
        this.risk.spouses[index].age;
      const i = this.risk.replacement_income.current_rate_of_return / 100;

      const PV = FV / Math.pow(1 + i, n);

      this.risk.spouses[index].present_value_for_education_needs = PV;

      return PV;
    },
    calculateTotalRetirementFundRequiredForSpouse() {
      let total = 0;
      this.risk.spouses.forEach((spouse, index) => {
        total += this.calculatePresentRetirementFundRequired(index);
      });

      this.risk.retirementPincome_for_spouse.total_retirement_fund_required_for_spouse = total;

      return this.nFormat(total);
    },
    onNextStep() {
      this.$emit("next", this.risk);
    },
    onPrevStep() {
      this.$emit("prev", this.risk);
    },
  },
  watch: {},
  mounted() {
    // this.$nextTick(() => {
    //   this.$refs.oneTomeCostForm.resetForm();
    // });
  },
});

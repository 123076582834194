
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import DescriptionModal from "./modal/Description.vue";

import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  profileDetailsValidatorForRiskPlanning,
  spouseValidatorForRiskPlanning,
} from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  name: "incme-household",
  props: ["theRisk"],
  components: {
    Form,
    // Field,
    ErrorMessage,
    ElCurrencyInput,
    DescriptionModal,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const aboutYouForm = ref(null);
    const submitAboutYouForm = () => aboutYouForm.value?.submit();

    return {
      nFormat,
      submitAboutYouForm,
    };
  },
  data() {
    const description = {
      cashAndCashEquivalents: `<p>Cash and cash equivalents refer to those items of asset that are cash which is most liquid or asset that can be converted into cash immediately with little lost in its value. These include saving/current account, fixed deposit and insurance cash value.</p>`,
      investmentAssets: `<p>Investment assets are assets that are acquired with the goal of generating income or appreciation in capital gains in anticipation of a future increase in value. Examples of investment assets include mutual funds, equity, bonds, and real estate investments. These assets are less liquid and have higher risks compared to cash/cash equivalents, therefore, they may generate higher returns or higher loss. </p>`,
      EPFDeathBenefit:`<p>The Death Benefit is a gesture of compassion by the EPF to the member’s next-of-kin (under EPF’s discretion). A one-time payment of RM2,500 will be considered and awarded to any of the deceased member’s eligible dependents. (widow/widower, child, or member's parents subject to member's marital status)</p><p>Reference: <a target='_blank' href='https://www.kwsp.gov.my/member/withdrawals/full/death'>https://www.kwsp.gov.my/member/withdrawals/full/death</a></p>`,
      SOCSODeathBenefit:`<p>Funeral Benefit will be paid to the eligible person if the employee dies as a result of employment injury or while he/she is in receipt of Permanent Disablement Benefit. In the absence of such person, the benefit will be paid to the person who actually incurs the funeral expenditure.</p><p>The amount paid will be the actual amount incurred or RM2,000 whichever is lower.</p><p>Reference: <a target='_blank' href='https://www.perkeso.gov.my/en/30-social-security-protection/social-security-protection-scheme.html#funeral-benefit'>https://www.perkeso.gov.my/en/30-social-security-protection/social-security-protection-scheme.html#funeral-benefit</a></p>`,
      life_insurance: `<p>Total amount of insurance's sum assured plus cash value of the insurance </p>`,
      EPFBalance: `<p>EPF balance is the total amount of money in your EPF account. </p>`,
    };

    return {
      risk: this.theRisk,
      description,
    };
  },
  methods: {
    onNextStep() {
      this.$emit("next", this.risk);
    },
    onPrevStep() {
      this.$emit("prev", this.risk);
    },
  },
  watch: {
    "risk.about_you.is_has_spouse": function (newValue, oldValue) {
      // console.log(newValue);
    },
  },
});


import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { OneTimeCostValidator } from "../../../utils/FinancialPlanningValidatorUtils";

export default defineComponent({
  name: "incme-household",
  props: ["theRisk"],
  components: {
    Form,
    Field,
    // ErrorMessage,
    // ElCurrencyInput,
    ElCurrencyInputNoSymbol,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const addSpouseModal = ref(null);
    const oneTomeCostForm = ref(null);
    const closeModal = () => Modal.getInstance(addSpouseModal.value)?.hide();
    const submitAboutYouForm = () => oneTomeCostForm.value?.submit();

    return {
      nFormat,
      addSpouseModal,
      closeModal,
      submitAboutYouForm,
    };
  },
  data() {
    const actions = "addSpouse";
    const spouse = {
      name: "",
      age: null,
      annual_income: null,
      retirement_age: null,
    };
    const dialogVisible_1 = false;
    const dialogVisible_2 = false;
    return {
      risk: this.theRisk,
      actions,
      spouse,
      dialogVisible_1,
      dialogVisible_2,
      // retirementIncomeValidator: OneTimeCostValidator,
    };
  },
  methods: {
    calculateFutureValue() {
      this.risk.children.forEach((child) => {
        const presentValue = child.present_annual_edu_cost;
        const inflationRate = this.risk.educational_cost
          .education_fees_inflation_rate;
        const yearsToCollege = child.years_to_college;
        const futureValue =
          presentValue * Math.pow(1 + inflationRate / 100, yearsToCollege);
        child.future_value_edu_cost = futureValue;
      });
    },
    calculatePresentValueAnnuityDue(){
      this.risk.children.forEach((child) => {
        const pmt = child.future_value_edu_cost;
        const i = this.calculateRealRateOfReturn()/100;
        const n = child.years_in_college;
        const PV = pmt * ((1 - Math.pow(1 + i, -n)) / i) * (1 + i);
        child.present_value_annuity_due = PV;
      });
    },
    calculatePresentValueEducationNeed(){
      this.risk.children.forEach((child) => {
        const fv = child.present_value_annuity_due;
        const i = this.risk.replacement_income.current_rate_of_return/100;
        const n = child.years_to_college;
        const PV = fv / Math.pow(1 + i, n);
        child.present_value_for_education_needs = PV;
      });
    },
    calculateRealRateOfReturn() {
      let inflationRate =
        this.risk.replacement_income.current_inflation_rate / 100;
      let rateOfReturn =
        this.risk.replacement_income.current_rate_of_return / 100;

      let i = (1 + rateOfReturn) / (1 + inflationRate) - 1;
          i = i * 100
      return parseFloat(i.toFixed(4));
    },
    calculateAll(){
      this.calculateFutureValue();
      this.calculatePresentValueAnnuityDue();
      this.calculatePresentValueEducationNeed();
    },
    onNextStep() {
      this.$emit("next", this.risk);
    },
    onPrevStep() {
      this.$emit("prev", this.risk);
    },
  },
  watch: {},
});


import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { OneTimeCostValidator } from "../../../utils/FinancialPlanningValidatorUtils";
import DescriptionModal from "./modal/Description.vue";

export default defineComponent({
  name: "incme-household",
  props: ["theRisk"],
  components: {
    Form,
    Field,
    ErrorMessage,
    ElCurrencyInput,
    // ElCurrencyInputNoSymbol,
    DescriptionModal,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const addSpouseModal = ref(null);
    const oneTomeCostForm = ref(null);
    const closeModal = () => Modal.getInstance(addSpouseModal.value)?.hide();
    // const submitAboutYouForm = () => oneTomeCostForm.value?.submit();

    return {
      nFormat,
      addSpouseModal,
      closeModal,
      // submitAboutYouForm,
    };
  },
  data() {
    const actions = "addSpouse";
    const spouse = {
      name: "",
      age: null,
      annual_income: null,
      retirement_age: null,
    };

    const description = {
      desired_annual_income: `<p>Desired annual income is estimated as 70% to 80% of your current income</p>`,
    };

    return {
      risk: this.theRisk,
      actions,
      spouse,
      description,
      // retirementIncomeValidator: OneTimeCostValidator,
    };
  },
  methods: {
    onCalculateIncomeReplacement(index, type) {
      const spouse = this.risk[type][index];
      this.risk[type][index].amount_of_annual_income =
        (spouse.percentage_of_annual_income / 100) *
        this.risk.about_you.annual_income;

      const pmt = spouse.amount_of_annual_income;
      const n = spouse.years_to_receive_income;
      const i = this.calculateRealRateOfReturn();
      const pv = this.calculatePresentValue(pmt, n, i);

      this.risk[type][index].total_income_replacement = pv;

      console.log(i);
    },
    calculateRealRateOfReturn() {
      let inflationRate =
        this.risk.replacement_income.current_inflation_rate / 100;
      let rateOfReturn =
        this.risk.replacement_income.current_rate_of_return / 100;

      return (1 + rateOfReturn) / (1 + inflationRate) - 1;
    },
    calculatePresentValue(pmt, n, i) {
      return pmt * ((1 - Math.pow(1 + i, -n)) / i) * (1 + i);
    },
    onNextStep() {
      this.$emit("next", this.risk);
    },
    onPrevStep() {
      this.$emit("prev", this.risk);
    },
  },
  async mounted() {
    this.onCalculateIncomeReplacement(0, "spouses");
  },
});
